<mat-drawer-container autosize style="background: white">

  <mat-drawer #drawer [mode]="'side'" position="end">
    <div class="container">
      <h3 class="padding-top-15">Filters</h3>
      <mat-divider></mat-divider>
      <mat-form-field class="padding-top-15">
        <mat-label>Location</mat-label>
        <mat-select [(ngModel)]="filters.location.value" (selectionChange)="updateFilter()">
          <mat-option value="">All locations</mat-option>
          <mat-option *ngFor="let location of filters.location.options | sortByName"
                      [value]="location.id">{{location.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field class="padding-top-15">
        <mat-label>External System</mat-label>
        <mat-select [(ngModel)]="filters.externalSystem.value" (selectionChange)="updateFilter()">
          <mat-option value="">All external systems</mat-option>
          <mat-option *ngFor="let externalSystem of filters.externalSystem.options"
                      [value]="externalSystem.code">{{externalSystem.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <button (click)="drawer.toggle()" mat-stroked-button>
        <mat-icon aria-hidden="false">close</mat-icon>
        Close
      </button>&nbsp;
      <button (click)="resetGrid()" mat-stroked-button color="accent">
        <mat-icon aria-hidden="false">refresh</mat-icon>
        Reset
      </button>
    </div>
  </mat-drawer>

  <mat-drawer-content style="padding-top: 15px">
    <h5 class="card-title text-muted">Unmatched records</h5>
    <mat-divider></mat-divider>

    <form class="form-inline list-employee-search-header">
      <div class="row">
        <div class="col col-lg-4">
          <mat-form-field class="toast-top-full-width" appearance="standard">
            <mat-label>Search by external employee ID or name</mat-label>
            <input type="text"
                   placeholder=""
                   aria-label="Search"
                   matInput
                   (input)="updateFilter()" [(ngModel)]="filters.searchQuery.value">
          </mat-form-field>
        </div>
        <div class="col col-lg-2">
          <button mat-icon-button color="primary" (click)="drawer.toggle()" class="margin-top-15">
            <mat-icon aria-hidden="false">tune</mat-icon>
            <span *ngIf="calculateActiveFiltersCount() > 0" class="badge badge-small">{{calculateActiveFiltersCount()}}</span>
          </button>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>

    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="isLoadingResults">
        <mat-spinner [diameter]="50" *ngIf="isLoadingResults"></mat-spinner>
      </div>

      <div class="table-box">
        <table mat-table [dataSource]="dataSource"
               matSort matSortActive="originType.name" matSortDisableClear matSortDirection="asc">

          <ng-container matColumnDef="externalSystemId" sticky>
            <th mat-header-cell *matHeaderCellDef>External Employee ID</th>
            <td mat-cell *matCellDef="let row">
          <span *ngIf="row.originalEntity?.originalId">
            {{getExternalId(row)}}
          </span>
              <mat-icon *ngIf="!row.originalEntity?.originalId" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="externalRarebreedId">
            <th mat-header-cell *matHeaderCellDef>External Rarebreed ID</th>
            <td mat-cell *matCellDef="let row">
          <span *ngIf="row.originalEntity?.rarebreedId">
            {{row.originalEntity?.rarebreedId}}
          </span>
              <mat-icon *ngIf="!row.originalEntity?.rarebreedId" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="originType.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>External System</th>
            <td mat-cell *matCellDef="let row">
              <a href="{{row.originalEntity.link}}" target="_blank" *ngIf="row.originalEntity.link && row.originType?.name">
                {{row.originType?.name}}
              </a>
              <span *ngIf="!row.originalEntity.link && row.originType?.name">
                {{row.originType?.name}}
              </span>
              <mat-icon *ngIf="!row.originType?.name" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef>Full Name</th>
            <td mat-cell *matCellDef="let row">
              {{row.fullName}}
              <mat-icon *ngIf="!row.fullName" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="locationName">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let row">
              {{row.locationName}}  <small *ngIf="row.locationName && row.sourceValue">(source value)</small>
              <mat-icon *ngIf="!row.locationName" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef>Reason</th>
            <td mat-cell *matCellDef="let row">
          <span *ngIf="row.unmatchReason">
            {{row.unmatchReason}}
          </span>
              <mat-icon *ngIf="!row.unmatchReason" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employeeStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Status</th>
            <td mat-cell *matCellDef="let row">
          <span *ngIf="row.employeeStatus">
            {{row.employeeStatus}}
          </span>
              <mat-icon *ngIf="!row.employeeStatus" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon">
                <mat-icon *ngIf="!element.loading">more_vert</mat-icon>
                <mat-spinner *ngIf="element.loading" diameter="20"></mat-spinner>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openMatchDialog(element)"
                        [disabled]="element.originType?.code === 'VETSOURCE' && element.sourceValue">
                  <mat-icon>compare_arrows</mat-icon>
                  <span>Match</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [ngStyle]="row.originType?.code === 'VETSOURCE' && row.sourceValue && {'background-color': 'lightgrey'}"
              [matTooltip]="row.originType?.code === 'VETSOURCE' && row.sourceValue ? 'Unable to proceed with matching. Vetsource location of this employee is not matched to a master location.' : ''"></tr>
        </table>
      </div>
      <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
