import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges
} from '@angular/core';
import {AahaAccount, Action, Employment, EmploymentEvent, MasterEntities} from '../../master.entities.models';
import {compareById, compareYesNoBoolean} from '../../utils';
import {FormControl} from '@angular/forms';
import {Employee} from '../../list/employee-list.model';
import {AuthenticationService} from '../../../login/auth.service';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {SpinnerService} from "../../../spinner/spinner.service";
import {MasterEmployeeService} from "../../master.employee.service";
import {finalize} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {MatSelectChange} from "@angular/material/select";
import {Observable} from "rxjs";
import {MasterEntitiesService} from "../../master.entities.service";

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})
export class EmploymentComponent implements OnInit, OnChanges {

  @Input() employment: Employment;
  @Input() employee: Employee;
  @Input() masterEntities: MasterEntities;
  @Output() employmentEventEmitter = new EventEmitter<EmploymentEvent>();
  hiredOn = new FormControl();
  effectiveOn = new FormControl();
  terminatedOn = new FormControl();
  benefitClassEffectiveDate = new FormControl();
  prosalEffectiveDate = new FormControl();
  payRateEffectiveDate = new FormControl();
  serviceExclusions = new FormControl();
  productExclusions = new FormControl();

  constructor(public authService: AuthenticationService,
              private spinnerService: SpinnerService,
              private masterEmployeeService: MasterEmployeeService,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.employment) {
      this.hiredOn.setValue(this.employment.hiredOn);
      this.effectiveOn.setValue(this.employment.effectiveOn);
      this.terminatedOn.setValue(this.employment.terminatedOn);
      this.benefitClassEffectiveDate.setValue(this.employment.benefitClassEffectiveDate);
      this.prosalEffectiveDate.setValue(this.employment.prosalInfo?.effectiveDate);
      this.payRateEffectiveDate.setValue(this.employment.payRate?.effectiveDate);
      this.serviceExclusions.setValue(this.employment.excludedAaahaAccounts.filter(
        item => item.revenueCategory === 'SERVICE').map(aahaAccount => aahaAccount.id));
      this.productExclusions.setValue(this.employment.excludedAaahaAccounts.filter(
        item => item.revenueCategory === 'PRODUCT').map(aahaAccount => aahaAccount.id));
    }
  }

  public compareOption(item1, item2) {
    return compareById(item1, item2);
  }

  isNew() {
    return !this.employment?.id;
  }

  getEmploymentIndex() {
    return this.isNew() ? 'new' : this.employment.id;
  }

  public compareByBool(item1, item2): boolean {
    return compareYesNoBoolean(item1, item2);
  }

  onExcludeSalaryChange($event: MatCheckboxChange) {
    this.updateEmployment(this.masterEmployeeService.toggleExcludeSalary(this.employee.id, this.employment.id, $event.checked));
  }

  onServiceExclusionsChange($event: MatSelectChange) {
    this.masterEntities.aahaAccounts.subscribe(aahaAccounts => {
      this.updateEmployment(this.masterEmployeeService.updateAahaServiceAccountsExclusions(
        this.employee.id, this.employment.id, $event.value.map(
          aahaAccountId => aahaAccounts.filter(item => item.id === aahaAccountId)[0])));
    });
  }

  onProductExclusionsChange($event: MatSelectChange) {
    this.masterEntities.aahaAccounts.subscribe(aahaAccounts => {
      this.updateEmployment(this.masterEmployeeService.updateAahaProductAccountsExclusions(
        this.employee.id, this.employment.id, $event.value.map(
          aahaAccountId => aahaAccounts.filter(item => item.id === aahaAccountId)[0])));
    });
  }

  updateEmployment(operation) {
    this.spinnerService.showSpinner();
    operation
      .pipe(
        finalize(() => {
          console.log('Employment has been updated.');
          this.spinnerService.hideSpinner();
        }))
      .subscribe(response => {
          this.toastrService.info(`Employment has been updated.`);
        },
        response => {
          this.toastrService.error(
            `Error updating employment : \n${response.error.status} :  ${response.error.error}`)
        }); };
}

@Pipe({
  name: 'sortByName'
})
export class SortByNamePipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value != null ? value.sort((a, b) => {
      let result = 0;
      if (a.name < b.name) {
        result = - 1;
      } else if (a.name > b.name) {
        result = 1;
      }
      return result;
    }) : value;
  }
}

@Pipe({
  name: 'aahaAccountRevenueCategory'
})
export class AahaAccountRevenueCategoryPipe implements PipeTransform {
  transform(items: any[], revenueCategory: string): any[] {
    return items?.filter(item => item.revenueCategory === revenueCategory);
  }
}
